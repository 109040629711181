import { useDispatch, useSelector } from "react-redux";
import { searchMenu } from "../../../data/index";
import { locationAction } from "../../../stores/Slices/LocationSlice";
import style from "../../../styles/sections/Banner.module.scss";
import SearchPostcode from "../SearchPostcode";

const Banner = () => {
  const dispatch = useDispatch();
  const { filterData, form } = useSelector((state) => state.locationReducer);

  const getClass = (item, key) => {
    let className = `${style.listItem}`;
    if (key === 0) {
      className = `${className} ${style.borderRT}`;
    } else {
      className = `${className} ${style.borderLT}`;
    }
    if (form === item.id) {
      className = `${className} ${style.listItemSelected}`;
    }
    return className;
  };

  return (
    <>
      <section className={style.bannerWrap}>
        <div className={`container-xl ${style.bannerContainer}`}>
          <div className={style.content}>
            <div className={style.headerWrap}>
              <h1 className={style.header}>
                Find Care Homes & Retirement Properties in your local area
              </h1>
            </div>
            <div className={style.queryWrap}>
              <div className={style.list}>
                {searchMenu.map((item, key) => (
                  <div
                    className={getClass(item, key)}
                    key={key}
                    onClick={() => {
                      dispatch(locationAction.toggleForm(item.id));
                    }}
                  >
                    <p>{item.title}</p>
                  </div>
                ))}
              </div>
              <div className={style.queryForm}>
                <SearchPostcode
                  style={style}
                  careTypeList={filterData ?? []}
                  hideDetect={true}
                  isOriginHome={true}
                />
              </div>
            </div>
          </div>
        </div>
        <span className={style.lineLf}></span>
        <span className={style.lineRt}></span>
      </section>
    </>
  );
};

export default Banner;
